import jQuery from "jquery";

/**
 *
 * Authors: Xavier.AMORENA At LaBRI.fr
 *
 * PageView jQuery Plugin
 *
 * $(".pageview").pageView({
 *   current: ?,
 *   payload: ?,
 *   configs: {},
 *   actions: { // List of actions
 *   }
 * });
 *
 * Page view example:
  $(".pageView").pageView({
    current: "INITIALIZE",
    payload: {},
    configs: {...},
    actions: {
      INITIALIZE: {
        create: function (view, config) {
          const _el = $(view.elem);
          ...
        },
        update: function (view, payload) {},
      },
      OPEN_SEARCH: {
        render: function (view) {
            $(view.elem).find("#detailView").modal("hide");
            ...
            $(view.elem).find("#masterView").show();
        },
      },
      OPEN_CREATE: {
        render: function (view) {
            $(view.elem).find("#masterView").hide();
            ...
            $(view.elem).find("#detailView").modal("show");
        },
      },
      CREATE: {
        create: function (view, config) {
            const _el = $(view.elem);
            _el.find("createForm").submit(function (e) {
                var _this = $(this);
                e.preventDefault();
                $.ajax({
                    type: "POST",
                    url: _this.attr("action"),
                    data: _this.serialize(),
                    success: function (e) {
                        view.dispatch("CREATE_SUCCESS", { data: e });
                    },
                    error: function (e) {
                        view.dispatch("CREATE_FAILURE", { data: e });
                    },
                }
            });
        },
        update: function (view, payload) {
            // reset form
        },
      },
      CREATE_SUCCESS: {
        update: function (view, payload) {
          showSuccessMessage("Message: " + payload.data);
          $(view.elem).find("#detailView").modal("hide");
          $(view.elem).find("#masterView").modal("hide");
          view.dispatch("INITIALIZE", {});
        },
      },
      CREATE_FAILURE: {
        update: function (view, payload) {
          showWarningMessage("Message: " + payload.data);
          $(view.elem).find("#detailView").modal("hide");
          $(view.elem).find("#masterView").modal("hide");
          view.dispatch("INITIALIZE", {});
        },
      },
      ...
    },
  });

  *
  **/

class PageAction {
    DEBUG = true;
    COUNTER = 0;
    constructor(view, config) {
        PageAction.COUNTER++;
        this.config = config != null ? config : {};
        if (Object.hasOwn(this.config, "name")) {
            this.name = this.config.name;
        } else {
            this.name = `action${PageAction.COUNTER}`;
        }
        if (Object.hasOwn(this.config, "create")) {
            this.create = this.config.create.bind(this);
        }
        if (Object.hasOwn(this.config, "update")) {
            this.update = this.config.update.bind(this);
        }
        if (Object.hasOwn(this.config, "render")) {
            this.render = this.config.render.bind(this);
        }
        this.create(view, this.config);
    }
    create(view, config) {
        if (PageAction.DEBUG) {
            console.log(
                `create name: ${this.name}, view: ${JSON.stringify(view)}, data: ${JSON.stringify(config)}`,
            );
        }
    }
    update(view, payload) {
        if (PageAction.DEBUG) {
            console.log(
                `update name: ${this.name}, view: ${JSON.stringify(view)}, data: ${JSON.stringify(payload)}`,
            );
        }
    }
    render(view) {
        if (PageAction.DEBUG) {
            conosle.log(
                `render name: ${this.name}, view: ${JSON.stringify(view)}`,
            );
        }
    }
}

class PageView {
    constructor(elem, config) {
        this.config = config != null ? config : {};
        if (typeof elem === "string") {
            this.root = elem;
            this.elem = $(this.root);
        } else {
            this.root = null;
            this.elem = elem;
        }
        this.template = this.config.template ?? null;
        this.configs = this.config.configs ?? {};
        this.actions = this.config.actions ?? {};
        if (this.template != null) {
            $(this.elem).html(this.template);
        }
        const actionNames = Object.keys(this.actions);
        for (let actionId = 0; actionId < actionNames.length; actionId++) {
            const actionName = actionNames[actionId];
            this.actions[actionName] = new PageAction(
                this,
                this.actions[actionName],
            );
        }
        this.currentAction = null;
        if (Object.hasOwn(this.config, "current")) {
            if (Object.hasOwn(this.config, "payload")) {
                this.dispatch(this.config.current, this.config.payload);
            } else {
                this.dispatch(this.config.current, {});
            }
        }
    }
    dispatch(actionName, payload) {
        if (
            actionName === undefined ||
            actionName === null ||
            actionName === ""
        ) {
            console.log(`action name not allowed: ${actionName}`);
            return false;
        }
        if (Object.hasOwn(this.actions, actionName)) {
            this.action = this.actions[actionName];
            if (PageAction.DEBUG) {
                console.log(`dispatch: ${actionName}`);
            }
            if (Object.hasOwn(this.action, "update")) {
                this.action.update(this, payload);
            }
            if (Object.hasOwn(this.action, "render")) {
                this.action.render(this);
            }
            return true;
        }
        console.log(`action name not recognized: ${actionName}`);
        return false;
    }
}

(($) => {
    $.fn.pageView = function (options) {
        return this.each(function () {
            const defaults = {};
            const settings = $.extend({}, defaults, options);
            return new PageView(this, settings);
        });
    };
})(jQuery);
