import $ from "jquery";

import "bootstrap";

import DataTable from "datatables.net-bs4";
import "datatables.net-autofill-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-colreorder-bs4";
import DateTime from "datatables.net-datetime";
import "datatables.net-fixedcolumns-bs4";
import "datatables.net-fixedheader-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-scroller-bs4";
import "datatables.net-searchpanes-bs4";

/* https://cssanimation.rocks/scroll-animations/
 */
function isElementInViewport(el) {
    let element = el;
    // special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        element = el[0];
    }
    const rect = element.getBoundingClientRect();
    return (
        (rect.top <= 0 && rect.bottom >= 0) ||
        (rect.bottom >=
            (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <=
                (window.innerHeight ||
                    document.documentElement.clientHeight)) ||
        (rect.top >= 0 &&
            rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight))
    );
}

function animateOnScroll() {
    const elementsToShow = document.querySelectorAll(".aos"); // Animate On Scroll
    for (const element of elementsToShow) {
        if (isElementInViewport(element)) {
            element.classList.add("animated");
        } else {
            element.classList.remove("animated");
            element.style.animation = null;
        }
    }
}

$(() => {
    $(document).ready(() => {
        animateOnScroll();
        $(".dropdown-toggle").dropdown();
    });
    $(document).scroll(function () {
        const nav = $("header.site-header");
        const scrolled = $(this).scrollTop() > nav.height();
        nav.toggleClass("scrolled", scrolled).toggleClass("shadow", scrolled);
        animateOnScroll();
    });
});
