import $ from "jquery";

function showMessage(title, message, mode, delay = 4) {
    const html = [
        `<div aria-atomic="true" aria-live="assertlive" class="toast bg-${mode !== undefined ? mode : "default"}"${
            delay > 0
                ? ` data-autohide="true" data-delay="${delay * 1000}"`
                : ' data-autohide="false"'
        } role="alert">`,
        '<div class="toast-header">',
        `<strong class="mr-auto">${title}</strong>`,
        '<button aria-label="Close" class="ml-2 mb-1 close" data-dismiss="toast" type="button">',
        '<span aria-hidden="true">&times;</span>',
        "</button>",
        "</div>",
        '<div class="toast-body">',
        message,
        "</div>",
        "</div>",
    ].join("\n");
    $("#messages").append(html).find(".toast:last").toast("show");
}

function showPrimaryMessage(message, delay) {
    ShowMessage("Information", message, "primary", delay);
}

function showWarningMessage(message, delay) {
    showMessage("Warning !", message, "warning", delay);
}

function showSuccessMessage(message, delay) {
    showMessage("Success", message, "success", delay);
}

function showFailureMessage(message, delay = 0) {
    showMessage("Failure", message, "danger", delay);
}

$(document).ready(() => {
    $(".toast").toast("show");
});

export {
    showPrimaryMessage,
    showWarningMessage,
    showSuccessMessage,
    showFailureMessage,
};
