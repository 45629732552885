import $ from "jquery";

window.$ = window.jQuery = $;
setupAjaxHeaders();

import "./theme.js";
import "./message.js";
import "./preview.js";
import "./page-view.js";
import "./render-helpers.js";
import "./cookies.js";
