import { showSuccessMessage } from "./message.js";

function answer_consent(s) {
    return (ev) => {
        console.log(s);
        console.log(ev);
        $.ajax({
            url: "/api/v1.0/consent/cookies",
            type: "POST",
            data: { cookie_consent: s },
            success: (e) => {
                showSuccessMessage("Consent agreements updated");
            },
            error: (e) => {
                console.log("Failed to apply consent agreements!");
            },
        });
        document.getElementById("cookie-consent-container").hidden = true;
    };
}

window.initializeCookies = () => {
    const consentAgreeButton = document.getElementById("cookie-consent-agree");
    if (consentAgreeButton) {
        consentAgreeButton.onclick = answer_consent(true);
    }
};
